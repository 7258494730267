import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import BannerLanding from "../components/BannerLanding";
import IframeResizer from "iframe-resizer-react";

import picFlowchart from "../assets/images/flowchart-2.png";
import pic08 from "../assets/images/pic08.jpg";
import pic09 from "../assets/images/pic09.jpg";
import pic10 from "../assets/images/pic10.jpg";

const inFlowPage = (props) => (
  <Layout>
    <Helmet>
      <title>inFlow interactive flowcharts | Mecosud B.V.</title>
      <meta
        name="description"
        content="Met onze dienst 'inFlow' voeg je eenvoudig interactieve beslisbomen
            toe aan jouw bestaande of nieuwe website en app. Ideaal voor (medische) richtlijnen,
            educatieve websites, e-learning of als onderdeel van jouw onboarding
            of klantenservice. 'inFlow' is een handelsnaam van Mecosud B.V."
      />
    </Helmet>

    <BannerLanding
      title="inFlow"
      subTitle="Interactieve beslisbomen voor jouw website of app"
      styleClass="style-inflow"
      photo={picFlowchart}
    />

    <div id="main">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2>Beslisbomen</h2>
          </header>
          <p>
            Met onze dienst 'inFlow' voeg je eenvoudig interactieve beslisbomen
            toe aan jouw bestaande of nieuwe website en app.
            <br />
            Ideaal voor (medische) richtlijnen, educatieve websites, e-learning
            of als onderdeel van jouw onboarding of klantenservice.
          </p>
        </div>
      </section>
      <section>
        <div style={{ width: "100%" }}>
          <IframeResizer
            src="https://www.inflowchart.com/chart/examples/tachycardia?embbeded=true"
            style={{ width: "1px", minWidth: "100%", maxHeight: "66vh" }}
            scrolling="yes"
          />
        </div>
      </section>
      <section id="two">
        <div className="inner">
          <header className="major">
            <h3>Keuze uit verschillende lay-outs</h3>
          </header>
          <p>
            U heeft deze keuze uit een diverse selectie van lay-outs. Zo weten
            we een goede ervaring te bieden welke aansluit bij uw use-case.
          </p>
        </div>
      </section>
      <section>
        <div className="inner">
          <header className="major">
            <h3>Co-creatie voor slimme widgets</h3>
          </header>
          <p>
            Door inhoud en techniek samen laten komen zijn we in staat
            doeltreffende toepassingen te maken voor jouw app, richtlijn of
            publicatie. Denk aan een <em>calculator</em> in een bepaalde stap
            van een medische beslisboom, of het tonen van een{" "}
            <em>(video)instructie</em> voor bepaalde handelingen in jouw support
            handleiding.
          </p>
          <p>Wij ontwerpen graag - samen met u - unieke toepassingen voor het gebruik in uw stappenschema.</p>
        </div>
      </section>
      <section>
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              padding: "2.5% 7.5%",
            }}
          >
            <IframeResizer
              src="https://www.inflowchart.com/chart/katnh/diagnostische-strategie-bij-verdenking-diepe-veneuze-trombose-arm/?embbeded=true"
              style={{ width: "1px", minWidth: "100%" }}
              scrolling="yes"
            />
          </div>
        </section>
      <section>
        <div className="inner">
          <header className="major">
          <h3>Geheel aangepast aan uw wensen</h3>
          </header>
          <p>
                Van het implementeren van jouw huisstijl tot het maken van
                gehele layouts op maat, alles is mogelijk. Zo weten wij een
                fantastische ervaring te bieden met onze interactieve
                beslisbomen.
              </p>
              <p>
                Wilt u meer te weten komen over deze dienst? Bekijk dan snel
                onze website.
              </p>
              <ul className="actions">
                <li>
                  <a
                    href="https://www.inflowchart.com"
                    target="_blank"
                    className="button"
                  >
                    Naar inflowchart.com
                  </a>
                </li>
              </ul>
        </div>
      </section>
    </div>
  </Layout>
);

export default inFlowPage;
