import React from 'react'

const BannerLanding = (props) => (
    <section id="banner" className={props.styleClass} style={{backgroundImage: `url(${props.photo})`}}>
        <div className="inner">
            <header className="major">
                <img src={props.image} alt={props.imageTitle} />
                <h1>{props.title}</h1>
            </header>
            <div className="content">
                <p>{props.subTitle}</p>
            </div>
        </div>
    </section>
)

export default BannerLanding
